
.survey-container{
    
}

.deactive-message{
    color: red;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.survey-container .survey{
    gap: 60px;
}

.survey-container .survey h2{
    background: rgba(34, 130, 100, 0.678);
    border-radius: 5px;
    padding: 7px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.survey-container .survey .answer-question{
    gap: 20px;
}

.survey-container .survey .answer-question .question{
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 20px;
    border-radius: 30px;
}

.survey-container .survey .answer-question .question span{
    padding: 4px;
    border-radius: 4px;
    font-size: 22px;
    color: rgb(34, 130, 100);
}

.survey-container .survey .answer-question .answer-container{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 20px;
    font-weight: bold;
    border-radius: 10px;
}

.survey-container .survey .answer-question .answer-container span{
    padding: 4px;
    font-size: 22px;
    color: rgb(34, 130, 100);
    border-radius: 4px;
}

.survey-container .survey .answer-question .answer-container .answer{
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all .1s ease;
}

.survey-container .survey .answer-question .answer-container .answer.active{
    background: rgb(0, 94, 0);
    color: white;
}

.survey-container .survey .answer-question .answer-container .answer.active:hover{
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    transform: none;
}

.survey-container .survey .answer-question .answer-container .answer:hover{
    box-shadow: rgba(64, 92, 196, 0.27) 0px 0px 10px, rgba(33, 82, 174, 0.05) 0px 0.25em 1em;
    transform: scale(1.01);
}

.survey-container .survey form{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(3, 100, 18, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;;
    border: 2px solid lightseagreen;
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.survey-container .survey form .input-group{
    display: flex;
    flex-direction: column;
    font-family: IRANSansfanum;
}

.survey .error-message{
    color: crimson;
    font-size: 15px;
    margin-top: 5px;
}

.survey-container .survey form .input-group label{
    font-weight: bold;
}

.survey-container .survey form .input-group input{
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    background: lightgray;
}

.survey-container .survey form button{
    padding: 10px 200px;
    width: 100%;
    background-color: lightseagreen;
    color: white;
    border: none;
    outline: none;
    border-radius: 20px;
    margin-top: 20px;
    transition: all .3s ease;
}

.survey-container .survey form button:hover{
    transform: translateY(-3px);
}

@media only screen and (max-width: 768px) {
    .survey-container .survey h2{
        font-size: 25px;
    }

    .survey-container .survey .answer-question .question{
        font-size: 16px;
    }

    .survey-container .survey form  button {
        padding: 10px 150px;
    }
}

@media only screen and (max-width: 524px) {
    .survey-container .survey h2{
        font-size: 15px;
    }

    .survey-container .survey .answer-question .question{
        font-size: 13px;
    }

    .survey-container .survey .answer-question .question span{
        font-size: 15px;
    }

    .survey-container .survey .answer-question .answer-container span{
        font-size: 15px;
    }

    .survey-container .survey .answer-question .answer-container .answer{
        font-size: 13px;
    }

    .survey-container .survey form{
        font-size: 13px;
    }

    .survey-container .survey form  button {
        padding: 10px 90px;
    }

    .deactive-message{
        font-size: 13px;
    }
}